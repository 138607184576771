<template>
	<div class="main-contents">
		<div class="tit">수요기업 기술인재 평가</div>
		<div class="search-box">
			<SelectComp tagName="div" title="직원구분" v-model="input.empDivCd" cdId="PRO102" :isAll="true"/><nbsp/>
			<SelectComp tagName="div" title="평가완료 유/무" v-model="input.evalYn" list="Y:평가완료,N:평가미완료" :isAll="true"/><nbsp/>
			<SelectComp tagName="div" title="검색조건" v-model="input.searchType" :list="searchType" defValue="TEC"/>
			<InputComp :title="searchType[input.searchType]" v-model="input.searchTxt" @keydown.enter="getList('1')"/>
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getList('1')"/>
		</div>
		<!-- 전체프로젝트 리스트 -->
		<div class="Board type3">
			<table class="Board_type3 admin">
				<colgroup>
					<col width="5%">
					<col width="7%">
					<col width="6%">
					<col width="12%">
					<col width="5%">
					<col width="30%">
					<col width="12%">
					<col width="7%">
					<col width="12%">
				</colgroup>
				<thead>
					<tr>
						<th>NO</th>
						<th>회원명</th>
						<th>회원번호</th>
						<th>회원소속</th>
						<th>직원구분</th>
						<th>프로젝트</th>
						<th>수요기업</th>
						<th>평가담당자</th>
						<th class="resume">수요기업 기술인재 평가</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="list && list.length > 0">
						<!-- 최대 25건 조회 -->
						<tr v-for="(item, idx) in list" :key="item.mberSeq" :class="{new:isNew(item)}">
							<td class="score"><div class="new" v-if="isNew(item)">new</div>{{(pageInfo.pageUnit * (pageInfo.pageIndex-1)) + idx + 1}}</td>
							<td class="score name" @click="popupMberInfo(item.tecMberSeq, 'tec')">{{item.tecMberNm}} &#9654;</td>
							<td class="score">{{item.tecMberSeq}}</td>
							<td class="score">{{item.tecCorpNm}}</td>
							<td class="score"><SelectComp type="text" v-model="item.tecEmpDivCd" cdId="PRO102"/></td>
							<td class="score name" @click="goProject(item)">{{item.projectNm}} &#9654;</td>
							<td class="score">{{item.corpNm}}</td>
							<td class="score name" @click="popupMberInfo(item.corpMberSeq, 'pmg')">{{item.corpMberNm}} &#9654;</td>
							<eval-div-grade tagNm="td" class="score resume" :value="item.evalItemDivGrade">
								<template v-slot="slot">
									<div class="star">
										<SelectComp type="text" :value="slot.item.cd" cdId="PRO110"/>
										<grade-img type="CLIENT_EVAL_ADM" :grade="slot.item.grade"/>
									</div>
								</template>
								<template v-slot:empty>
									<template  v-if="$store.state.userInfo.mberDivCd == '00' || $store.state.userInfo.mberSeq == item.admMgrMberSeq">
										<div class="btn rq" @click="clickEval(item)">평가요청 &#9654;</div>
									</template>
									<template v-else>-</template>
								</template>
							</eval-div-grade>
						</tr>
					</template>
					<tr v-else>
						<!-- 관련내용 없을 경우 -->
						<td colspan="17" class="none">등록된 회원이 없습니다!</td>
					</tr>
				</tbody>
			</table>
		</div>

		<!-- 페이징부분 -->
		<paging-comp :pageInfo="pageInfo" @page-click="goPage"/>
	</div><!-- //main-contents -->
</template>
<script>
import pagingComp from '@/components/PagingComp.vue';
import gradeImg from "@/components/highpro/GradeImg.vue";
import evalDivGrade from '@/components/highpro/EvalDivGrade.vue';

export default {
	components: { pagingComp, evalDivGrade, gradeImg },
	data() {
		return {
			input : {
				empDivCd: '',
				evalYn: '',
				searchType: 'TEC',
				searchTxt: '',
			},
			list : {},
			pageInfo : {},
			//searchType: [{cd:'TEC', cdNm:'기술인재명'}, {cd:'EVAL', cdNm:'평가담당자명'}, {cd:'EVAL', cdNm:'수요기업명'}, {cd:'EVAL', cdNm:'프로젝트명'}]
			searchType: {'TEC':'기술인재명', 'EVAL':'평가담당자명', 'CORP':'수요기업명', 'PROJ':'프로젝트명'},
		};
	},
	mounted(){
		var keys = Object.keys(this.input);
		for(var key in this.$route.params){
			if(keys.indexOf(key) >= 0){
				this.input[key] = this.$route.params[key];
			}
		}

		this.getList();
	},
	methods: {
        getList(div, isScroll = false){
			if(div) this.input.pageIndex = 1;
			
            this.$.httpPost('/api/mem/adm/evl/getEvalList', this.input)
                .then(res => {
                    this.list = res.data.list;
                    this.pageInfo = res.data.pageInfo;
                    if(isScroll) {
						window.scroll(0, 0);
					}
                }).catch(this.$.httpErrorCommon);
        },
		isNew(item){
			return new Date().add(-7, 'day') < this.$format.date(item.evalDate, 'date') ? true : false;
		},
		goPage(page){
			this.input.pageIndex = page;
			this.getList();
		},
		popupMberInfo(reqMberSeq, div){
			//console.log('popupMberInfo', reqMberSeq, div);
			this.$.popup('/adm/mem/MEM923P02', { reqMberSeq, div });
		},
		clickEval(item){
			//console.log('clickEval', item);
			if(!item.evalTabSeq){
				this.reqEval(item);
			}
		},
		goProject(item){
			this.$router.push({name:'MEM911M02', query:{projectSeq:item.projectSeq}, params:{caller:{name:this.$route.name, params:this.input}}});
		},
		reqEval(item){
			//console.log('reqEval', item);
			this.$.popup('/adm/mem/MEM912P03', {interviewReqSeq : item.interviewReqSeq});
		},
	},
};
</script>
